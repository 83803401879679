





































































































import Vue from "vue";
import BookingDialog from "@/components/BookingDialog.vue";

type Doctor = {
  firstName: number;
  lastName: string;
  specialization: string;
  clinicId: number;
  drId: number;
};

export default Vue.extend({
  name: "BookingCalendar",
  components: {
    BookingDialog,
  },
  props: {
    interval: {
      type: String,
      default: "Arzt",
    },
    calendar: {
      type: Array,
    },
    clinicId: {
      type: String,
    },
  },
  data() {
    return {
      schedule: [],
      showBookingDialog: false,
      startDate: new Date(),
      timespan: 6,
      bookingDialogTimeString: "",
      clinicDoctors: [],
      selectedDoctor: 0,
    };
  },
  async mounted() {
    await this.updateClinicDoctors();
    this.updateSchedule();
  },
  methods: {
    setBookingDialogTimeString(date: string, time: string) {
      this.bookingDialogTimeString = `${date}T${time}`;
    },
    getFormattedDate(date: Date) {
      return `${date.getDate().toString().padStart(2, "0")}.${(
        date.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}.${date.getFullYear()}`;
    },
    async increaseStartDate() {
      this.startDate = this.addDays(this.startDate, this.timespan);
      await this.updateSchedule();
    },
    async decreaseStartDate() {
      this.startDate = this.addDays(this.startDate, -this.timespan);
      await this.updateSchedule();
    },
    addDays(date: Date, days: number) {
      let tempDate = new Date(date);
      tempDate.setDate(tempDate.getDate() + days);
      return tempDate;
    },
    async updateSchedule() {
      let myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");
      let raw = JSON.stringify({
        startDate: this.getFormattedDate(this.startDate),
        endDate: this.getFormattedDate(
          this.addDays(this.startDate, this.timespan)
        ),
        clinicId: this.clinicId,
        drId: this.selectedDoctor,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      let response = await fetch(
        "https://mdic-backend.herokuapp.com/api/v1/user/appointment/",
        requestOptions
      );
      let result = await response.json();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let schedule: any = {};
      for (const appointment of result) {
        if (!schedule[appointment["date"]]) {
          schedule[appointment["date"]] = [];
        }
        schedule[appointment["date"]].push(appointment["time"]);
      }
      this.schedule = schedule;
    },
    async updateClinicDoctors() {
      var requestOptions = {
        method: "GET",
      };

      let response = await fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/${this.clinicId}/dr`,
        requestOptions
      );
      let result = await response.json();
      let clinicDoctors = result.map((dr: Doctor) => {
        return {
          text: dr.firstName + " " + dr.lastName,
          specialization: dr.specialization,
          id: dr.drId,
        };
      });
      this.selectedDoctor = clinicDoctors[0].id;
      this.clinicDoctors = clinicDoctors;
    },
  },
});
