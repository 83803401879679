<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="30vw"
      :fullscreen="$vuetify.breakpoint.mobile"
    >
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1">
          Versicherung
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-select v-model="insurance" outlined dense></v-select>
          <v-btn color="primary" @click="currentStep = 2"> Continue </v-btn>
          <v-btn text @click.stop="closeDialog()"> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 2" step="2">
          Kategorie
        </v-stepper-step>
        <v-stepper-content step="2">
          <v-select v-model="category" outlined dense></v-select>
          <v-btn color="primary" @click="currentStep = 3"> Continue </v-btn>
          <v-btn text @click.stop="closeDialog()"> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 3" step="3">
          Besuchsgrund
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-text-field v-model="reasonForVisit" outlined dense></v-text-field>
          <v-btn color="primary" @click="currentStep = 4"> Continue </v-btn>
          <v-btn text @click.stop="closeDialog()"> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 3" step="4">
          Buchung
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-btn color="primary" @click="sendAppointmentRequest()">
            Terming bestätigen
          </v-btn>
          <v-btn text @click.stop="closeDialog()"> Cancel </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-dialog>
    <v-snackbar
      v-model="successSnackbarVisible"
      timeout="4000"
      color="primary"
      height="60px"
    >
      <h3>Der Termin wurde erfolgreich gebucht!</h3>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbarVisible"
      timeout="4000"
      color="error"
      height="60px"
    >
      <h3 class="white--text">
        Leider haben sie schon mehr als einen Termin gebucht, bitte warten sie
        bis dieser akzeptiert wird.
      </h3>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "BookingDialog",
  props: {
    value: Boolean,
    timeString: String,
    clinicId: String,
    selectedDoctor: Number,
  },
  data() {
    return {
      currentStep: 3,
      insurance: "",
      category: "",
      reasonForVisit: "",
      successSnackbarVisible: false,
      errorSnackbarVisible: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    closeDialog() {
      this.show = false;
      this.currentStep = 3;
    },
    async sendAppointmentRequest() {
      let myHeaders = new Headers();
      myHeaders.append("Authorization", this.$store.getters.getToken);
      myHeaders.append("Content-Type", "application/json");
      let temp = this.timeString.split("T")[0].split(",");
      let output = `${temp[2]}.${temp[1]}.${temp[0]}T${
        this.timeString.split("T")[1]
      }`;

      let raw = JSON.stringify({
        startDate: output,
        message: this.reasonForVisit,
        clinicId: this.clinicId,
        drId: this.selectedDoctor,
      });

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
      };

      let response = await fetch(
        "https://mdic-backend.herokuapp.com/api/v1/user/appointment/request/add",
        requestOptions
      );
      if (response.status == 200) {
        this.successSnackbarVisible = true;
      } else {
        this.errorSnackbarVisible = true;
      }
      this.closeDialog();
    },
  },
});
</script>
