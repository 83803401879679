














import Vue from "vue";

export default Vue.extend({
  name: "PraxisDetailsInfoPanel",
  props: {
    icon: {
      type: String,
    },
    headline: {
      type: String,
    },
    content: {
      type: String,
    },
  },
});
