<template>
  <div>
    <v-row class="d-flex justify-center align-center light-blue lighten-2">
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="4" md="3">
            <v-avatar size="90">
              <v-img :src="imageUrl" />
            </v-avatar>
          </v-col>
          <v-col cols="8" md="9">
            <h1 class="white--text">
              {{ profile.title }}
            </h1>
            <h2 class="white--text">{{ profile.specialization }}</h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <PraxisDetailsInfoPanel
          v-for="section in profile.sections"
          :key="section.id"
          :icon="section.icon"
          :headline="section.title"
          :content="section.content"
          class="ma-1"
        />
      </v-col>
      <v-col cols="12" md="3">
        <BookingCalendar :clinicId="id" style="position: sticky; top: 4.5rem" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PraxisDetailsInfoPanel from "@/components/PraxisDetailsInfoPanel.vue";
import BookingCalendar from "@/components/BookingCalendar.vue";
import imageUrls from "../store/doctorImageUrls";

import Vue from "vue";
export default Vue.extend({
  name: "PraxisDetails",
  components: {
    PraxisDetailsInfoPanel,
    BookingCalendar,
  },
  data() {
    return {
      profile: {},
    };
  },
  mounted() {
    this.getGeneralInfo(this.id);
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    imageUrl() {
      return imageUrls[this.id % imageUrls.length];
    },
  },
  methods: {
    getGeneralInfo(clinicId) {
      var requestOptions = {
        method: "GET",
      };

      fetch(
        `https://mdic-backend.herokuapp.com/api/v1/clinic/dr/profile/${clinicId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          result.sections = result.sections.sort((a, b) => {
            return a.id - b.id;
          });
          Vue.set(this, "profile", result);
          this.profile = result;
        })
        .catch((error) => console.log("error", error));
    },
  },
});
</script>
